/**
 * @generated SignedSource<<940f1280f873734e65c76a18a9408564>>
 * @relayHash 29f99dd6cfe45b938e5817f3c831fba7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/505.0.0-2025-04-09T14:59:42.267Z/useItemShippingPricesQuery

import { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type ItemShippingPriceItem = {
  hasPrequote?: boolean | null;
  itemId?: string | null;
  quantity?: number | null;
};
export type useItemShippingPricesQuery$variables = {
  buyerId?: string | null;
  items?: ReadonlyArray<ItemShippingPriceItem | null> | null;
  prequoteBlockListTestArm?: string | null;
  userCountryCode?: string | null;
  userZipCode?: string | null;
};
export type useItemShippingPricesQuery$data = {
  readonly viewer: {
    readonly itemsShippingPrice: ReadonlyArray<{
      readonly itemId: string | null;
      readonly shipmentQuote: {
        readonly buyerTotalAmount: {
          readonly convertedAmountList: ReadonlyArray<{
            readonly amount: number | null;
            readonly currency: Currency | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};
export type useItemShippingPricesQuery = {
  response: useItemShippingPricesQuery$data;
  variables: useItemShippingPricesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "buyerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "items"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prequoteBlockListTestArm"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userZipCode"
},
v5 = [
  {
    "kind": "Variable",
    "name": "buyerId",
    "variableName": "buyerId"
  },
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "userCountryCode"
  },
  {
    "kind": "Variable",
    "name": "items",
    "variableName": "items"
  },
  {
    "kind": "Variable",
    "name": "prequoteBlockListTestArm",
    "variableName": "prequoteBlockListTestArm"
  },
  {
    "kind": "Literal",
    "name": "useLiveEndpoint",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "zipCode",
    "variableName": "userZipCode"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "itemId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "FinancialAmount",
  "kind": "LinkedField",
  "name": "buyerTotalAmount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "convertedAmountList",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useItemShippingPricesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "RelevantShippingInfo",
            "kind": "LinkedField",
            "name": "itemsShippingPrice",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShipmentQuote",
                "kind": "LinkedField",
                "name": "shipmentQuote",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useItemShippingPricesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "RelevantShippingInfo",
            "kind": "LinkedField",
            "name": "itemsShippingPrice",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShipmentQuote",
                "kind": "LinkedField",
                "name": "shipmentQuote",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/505.0.0-2025-04-09T14:59:42.267Z/useItemShippingPricesQuery",
    "metadata": {},
    "name": "useItemShippingPricesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "dd7c234ed1fdcfc6bb8ea838224e03a2";

export default node;
